
const ourServicesList = [
  {
    title: 'Understand the market',
    shortDescription: 'Get a holistic view of the healthcare and pharma market through our insightful ',
    link: '/consulting/market-assessment-services',
    imageSrc: 'img/home/png/understand-the-market.png',
    altText: 'Get a holistic view of the healthcare and pharma market through our insightful ',
    buttonTitle: 'Know More',
  },
  {
    title: 'Estimate the forecast',
    shortDescription: 'Be the leader in the pharma market with the help of our 10-year market and epidemiology forecast',
    link: '/report-store.php?category=Market Insight',
    imageSrc: 'img/home/png/estimate-the-forecast.png',
    altText: 'Be the leader in the pharma market with the help of our 10-year market and epidemiology forecast',
    buttonTitle: 'Know More',
  },
  {
    title: 'Analyze the pipeline',
    shortDescription: 'Gain rich knowledge of the upcoming therapies for better business-related decisions',
    link: '/report-store.php?category=Pipeline Insight',
    imageSrc: 'img/home/png/analyse-the-pipeline.png',
    altText: 'Gain rich knowledge of the upcoming therapies for better business-related decisions',
    buttonTitle: 'Know More',
  },
  {
    title: 'Monitor the competitors',
    shortDescription: 'Know and inspect your competitors to stand out in the industry',
    link: '/consulting/competitive-intelligence-services',
    imageSrc: 'img/home/png/monitor-the-competitors.png',
    altText: 'Know and inspect your competitors to stand out in the industry',
    buttonTitle: 'Know More',
  },
  {
    title: 'Streamline your journey',
    shortDescription: 'Consulting services that help in meeting desired goals through optimized strategy',
    link: '/consulting/asset-prioritizaton-services',
    imageSrc: 'img/home/png/streamline-your-journey.png',
    altText: 'Consulting services that help in meeting desired goals through optimized strategy',
    buttonTitle: 'Know More',
  },
  {
    title: 'Accelerate your growth',
    shortDescription: 'Exploit innovative ideas through our in-depth and curated R&D services',
    link: '/consulting/business-development-services',
    imageSrc: 'img/home/png/accelerate-your-growth.png',
    altText: 'Exploit innovative ideas through our in-depth and curated R&D services',
    buttonTitle: 'Know More',
  }
];
const accordionList = [
  {
    title: 'Customizable Reports',
    content: 'Customized reports specific to geographies, clinical trials, epidemiological trends, and markets.',
    link: '/report-store.php',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Solutions',
    content: 'Identify your needs, and our analysts will provide granular-level market intelligence to fulfill them.',
    link: '/request-for-proposal',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Competitive Intelligence',
    content: 'Custom-based analysis of the competitors to meet your unique needs.',
    link: '/consulting/competitive-intelligence-services',
    buttonTitle: 'Know More',
  },
  {
    title: 'Customizable Packages',
    content: 'Hassle-free subscription-based report packages the way you desire through our PharmDelve.',
    link: '/pharmdelve',
    buttonTitle: 'Know More',
  },
];
const counterList = [
  {
    title: 'Reports',
    imageSrc: 'img/home/svg/research_reports.svg',
    altText: 'Reports',
    numbers: '7000',
  },
  {
    title: 'Consulting Projects',
    imageSrc: 'img/home/svg/consulting_projects.svg',
    altText: 'Consulting Projects',
    numbers: '500',
  },
  {
    title: 'Satisfied Clients',
    imageSrc: 'img/home/svg/satisfied_clients.svg',
    altText: 'Satisfied Clients',
    numbers: '1000',
  },
  {
    title: 'Customized Reports',
    imageSrc: 'img/home/svg/customized_reports.svg',
    altText: 'Customized Reports',
    numbers: '1200',
  },
];
const blogList = [
  {
    title: "Breaking Boundaries: Innovations and Updates in Schizophrenia Treatment",
    shortDescription: "According to the World Health Organization (WHO), schizophrenia is defined as a chronic and severe mental disorder that significantly impairs the perception of reality and causes people to think, feel, and perceive the world differently than the general population.",
    link: "/blog/schizophrenia-treatment-and-management",
    imageSrc: "img/home/png/duchenne-muscular-dystrophy-market-outlook-featured.png",
    altText: "Breaking Boundaries: Innovations and Updates in Schizophrenia Treatment",
  },
  {
    title: "Novartis\’ LUTATHERA for GEP-NET Treatment: Ray of Hope for Pediatric Patients",
    shortDescription: "On 23rd April 2024, the FDA approved LUTATHERA (also known as lutetium Lu 177 dotatate) for the treatment of pediatric patients of 12 years and older with somatostatin receptor (SSTR)-positive gastro-entero-pancreatic neuroendocrine tumors (GEP-NETs), including foregut, midgut, and hindgut neuroendocrine tumors. Somatostatin receptor type 2 (SSTR2) is expressed in 80-90% of GEP-NET tumors. LUTATHERA has already received approval for GEP-NET treatment for adults in 2018. The recent approval was based on pharmacokinetic (PK), dosimetry, and safety data from NETTER-P, where findings showed that the safety profile of lutetium Lu 177 dotatate in pediatric patients 12 to 17 years of age was consistent with that observed in adult patients. It’s worth noting that this application was granted priority review and orphan drug designation. By leveraging the potency of radioactive atoms and directing them toward advanced cancer cells, RLT theoretically enables precise radiation delivery to targeted cells throughout the body.",
    link: "/blog/lutathera-for-gep-net-treatment",
    imageSrc: "img/home/png/novartis-lutathera-for-gep-net-treatment-ray-of-hope-for-pediatric-patients.png",
    altText: "Novartis\’ LUTATHERA for GEP-NET Treatment: Ray of Hope for Pediatric Patients",
  },
  {
    title: "Empowering Change: REZDIFFRA\’s Trailblazing Journey in NASH Treatment",
    shortDescription: "Liver disease emerges as a burgeoning global health concern, presenting a myriad of formidable challenges. From viral hepatitis to alcoholic liver disease, nonalcoholic fatty liver disease (NAFLD), liver cirrhosis, and hepatocellular carcinoma (HCC), these conditions collectively afflict millions, exacting a toll of morbidity, mortality, and economic burden. Within this landscape, NAFLD/nonalcoholic steatohepatitis (NASH) recently renamed MASH (metabolic dysfunction-associated steatohepatitis) assumes prominence as a pivotal contributor. NAFLD, a comprehensive term encapsulating a spectrum of liver afflictions, encompasses everything from benign steatosis to progressive hepatocellular injury accompanied by fibrosis, cirrhosis, and, in severe instances, HCC.",
    link: "/blog/rezdiffra-journey-in-nash-treatment",
    imageSrc: "img/home/png/empowering-change-rezdiffras-trailblazing-journey-in-nash-treatment.png",
    altText: "Empowering Change: REZDIFFRA\’s Trailblazing Journey in NASH Treatment",
  },
  {
    title: "Revolutionizing Psoriasis Treatment: Advancements in the US Market",
    shortDescription: "Psoriasis is one of the most frequent chronic inflammatory dermatoses in the world, with variable prevalence globally. Important factors in the variation of the prevalence of psoriasis include age, gender, geography, and ethnicity, probably due to genetic and environmental factors. Higher prevalence rates have been reported at higher latitudes and in Caucasians compared with other ethnic groups.",
    link: "/blog/us-psoriasis-treatment",
    imageSrc: "img/home/png/revolutionizing-psoriasis-treatment-advancements-in-the-us-market.png",
    altText: "Revolutionizing Psoriasis Treatment: Advancements in the US Market",
  },
  {
    title: "ENHERTU: Another Triumph to Celebrate for AstraZeneca and Daiichi Sankyo",
    shortDescription: "ENHERTU lies at the core of AstraZeneca and Daiichi Sankyo’s objectives for advancing in oncology. This collaboration has notably broadened the antibody-drug conjugates impact in the United States. On April 5, 2024, the FDA approved ENHERTU to treat HER2-positive solid tumors in adults who have received previous systemic treatment and have exhausted other available treatment options.",
    link: "/blog/enhertu-for-her2-positive-solid-tumors",
    imageSrc: "img/home/png/enhertu-another-triumph-to-celebrate-for-astrazeneca-and-daiichi-sankyo.png",
    altText: "ENHERTU: Another Triumph to Celebrate for AstraZeneca and Daiichi Sankyo",
  },
  {
    title: "Idorsia\’s Tryvio Success in Hypertension Treatment After J&J \‘Blunder\’?",
    shortDescription: "After Johnson & Johnson reversed its decision last year regarding the acquisition of Tryvio (aprocitentan) in 2017, the original manufacturer of the hypertension treatment drug, Idorsia Pharmaceuticals has successfully guided the drug through the FDA approval process.",
    link: "/blog/tryvio-for-hypertension-treatment",
    imageSrc: "img/home/png/idorsias-tryvio-success-in-hypertension-treatment-after-j&j-blunder.png",
    altText: "Idorsia\’s Tryvio Success in Hypertension Treatment After J&J \‘Blunder\’?",
  },
];
const reportList = {
  content: [
    {
      title: 'Latest Reports',
      description: 'Get comprehensive insights into the healthcare industry and epidemiology-based market forecasts with a 360° panoramic picture of the market alongside the latest trends, developments, and future opportunities.',
      shortTitle: 'Report Store',
      shortDescription: 'With over 7000 research reports in the repository covering 27 therapeutic areas, stay updated about the most recent trends, news, and developments in the healthcare domain.',
      buttonTitle: 'Explore Now',
    },
  ],
  items: [
    {
      link: '/report-store/oncolytic-virus-cancer-therapy-market',
      imageSrc: 'products/report-image/1723606581164-oncolytic-virus-market.png',
      altText: 'Oncolytic Virus Market',
    },
    {
      link: '/report-store/non-muscle-invasive-bladder-cancer-market',
      imageSrc: 'products/report-image/1723606754314-non-muscle-invasive-bladder-cancer.png',
      altText: 'Non-Muscle Invasive Bladder Cancer (NMIBC) Market',
    },
    {
      link: '/report-store/psoriatic-arthritis-market',
      imageSrc: 'products/report-image/1723606797526-psoriatic-arthritis-market.png',
      altText: 'Psoriatic Arthritis Market',
    },
    {
      link: '/report-store/graft-versus-host-disease-gvhd-market',
      imageSrc: 'products/report-image/1723606713138-graft-versus-host-disease.png',
      altText: 'Graft Versus Host Disease Market',
    },
    {
      link: '/report-store/weight-loss-weight-management-obesity-market',
      imageSrc: 'products/report-image/1723606633717-obesity.png',
      altText: 'Obesity Market',
    },
  ],
};
export {
  ourServicesList,
  accordionList,
  counterList,
  blogList,
  reportList,
}